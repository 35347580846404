<template>
  <div>
    <a-spin :spinning="loading">
      <a-form ref="form" :model="formState" name="form" @finish="onSearch">
        <a-row>
          <a-col>
            <a-form-item class="ui-form__item" label="演出名称" name="name">
              <a-input v-model:value="formState.name" placeholder="请输入演出名称"></a-input>
            </a-form-item>
          </a-col>
          <a-col>
            <a-form-item class="ui-form__item" label="演出分类" name="classify">
              <a-input v-model:value="formState.classify" placeholder="请输入演出分类"></a-input>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="18">
            <a-button type="link" style="background: rgb(26, 182, 103); color: white;">导 出</a-button>
          </a-col>
          <a-col :span="6" style="text-align: right">
            <a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
            <a-button @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>

      <div style="margin-top: 20px;">
        <a-table rowKey="id" :pagination="pagination" :columns="columns" :dataSource="list">
          <template #bodyCell="{ column, record, index }">
            <template v-if="column.key === 'index'">
              {{ index + 1 }}
            </template>
            <template v-if="column.key === ''">
              <a-image style="width: 60px;" :src="record.posterUrl"></a-image>
            </template>
          </template>
        </a-table>
      </div>
    </a-spin>
  </div>
</template>

<script>
export default {
  components: {  },
  data() {
    return {
      loading: false,
      searchData: {},
      formState: {

      },
      list: [{id:1,title:'1'}],
      columns: [
        {
          title: '序号',
          key: 'index',
        },
        {
          title: '演出名称',
          dataIndex: '',
        },
        {
          title: '演出海报',
          key: '',
        },
        {
          title: '演出分类',
          key: '',
        },
        {
          title: '演出简介',
          dataIndex: '',
        },
      ],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        pageSizeOptions: ['10','20', '50', '100', '500'],
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        }
      },
    }
  },
  methods: {
    onBack(isRef) {
      this.showChild = false;
      if (isRef) {
        this.$nextTick(() => {
          this.getData();
        })
      }
    },
    onSearch(){

    },
    reset(){
      this.$refs.form.resetFields();
    },
  }
}
</script>

<style scoped>
.ui-form__item {
  margin-right: 30px;
}
</style>
